export const FirebaseConfig = {
	"projectId": "apnidelivery-afdc3",
	"appId": "1:845212719898:web:ff9a7f0e9c8e0f6af7aaa3",
	"databaseURL": "https://apnidelivery-afdc3-default-rtdb.firebaseio.com",
	"storageBucket": "apnidelivery-afdc3.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDPNRx9pHmGRRatIyfQNL50Dev5pK2e8eY",
	"authDomain": "apnidelivery-afdc3.firebaseapp.com",
	"messagingSenderId": "845212719898",
	"measurementId": "G-JSQ6Q9HJBL"
};